<template>
  <v-container fluid class="text-center">
    <v-row>
      <v-col cols="12">
        <v-card class="elevation-5 mt-5 mx-5">
          <v-toolbar dense dark color="secondary" elevation="2">
            <v-toolbar-title>
              Plantillas
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn text small @click="dialogs.new = true">
              Agregar
              <v-icon right>mdi-plus</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-title>
            <v-btn small :loading="loading" elevation="0" color="secondary" outlined class="mx-2" @click="refresh()">
              Actualizar
              <v-icon right>mdi-refresh</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" class="mx-2" single-line hide-details maxlength="150"></v-text-field>
          </v-card-title>
          <v-data-table v-model="selected" :headers="headers" :items="items" :loading="loading" :search="search"
          :footer-props="{ 'items-per-page-text': 'Elementos por página', 'items-per-page-options': [20, 50, -1] }" :items-per-page="10" item-key="id" class="elevation-1" dense>
            <v-progress-linear slot="progress" color="secondary" indeterminate></v-progress-linear>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="primary" dark v-bind="attrs" v-on="on" :disabled="loading" @click="view(item)">mdi-pencil</v-icon></template><span>Editar</span></v-tooltip>
              <v-tooltip top><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="primary" dark v-bind="attrs" v-on="on" :disabled="loading" @click="forms.import.plantilla = item.idplantilla; dialogs.import = true">mdi-file-upload</v-icon></template><span>Importar archivo</span></v-tooltip>
              <v-tooltip top><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="success" dark v-bind="attrs" v-on="on" :disabled="loading" @click="downloadFile(item.idplantilla)">mdi-file-excel</v-icon></template><span>Descargar archivo</span></v-tooltip>
              <v-tooltip right><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="error" dark v-bind="attrs" v-on="on" :disabled="loading" @click="remove(item.idplantilla)">mdi-delete</v-icon></template><span>Eliminar</span></v-tooltip>
            </template>
            <template v-slot:[`footer.page-text`]="props">
              Página {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }}
            </template>
            <template v-slot:no-data>
              No hay información disponible
            </template>
            <template v-slot:no-results>
              No se obtuvieron resultados
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogs.new" persistent max-width="600px">
      <v-card>
        <v-toolbar dense dark color="secondary" elevation="2">
          <v-toolbar-title>Nueva plantilla</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn text small :loading="loading" @click="save()">
            Guardar
            <v-icon right>mdi-check</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="formNew" lazy-validation>
              <v-row>
                <v-col cols="12">
                  <v-text-field v-model="forms.new.nombre" label="Nombre" :rules="rules.nombre" :disabled="loading" required></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete v-model="forms.new.fuente_emision" :items="catalogues.fuentes_emisiones" item-text="nombre" item-value="idfuente_emision" label="Fuente de emisión" :rules="rules.fuente_emision" :disabled="loading" required></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="forms.new.operacion" label="Operación" :rules="rules.operacion" :disabled="loading" required></v-text-field>
                </v-col>
              </v-row>
              <template v-if="forms.new.columnas.length">
                <v-divider class="ma-5"></v-divider>
                <v-row justify="center">
                  <span class="font-weight-bold">Columnas</span>
                </v-row>
              </template>
              <template v-for="(item, idx) in forms.new.columnas">
                <v-row :key="idx">
                  <v-col cols="4">
                    <v-text-field v-model="item.id" label="Id" :rules="rules.columna.id" :disabled="loading" required></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field v-model="item.nombre" label="Nombre" :rules="rules.columna.nombre" :disabled="loading" required></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-autocomplete v-model="item.tipo" :items="catalogues.tipos" label="Tipo" :rules="rules.columna.tipo" :disabled="loading" required></v-autocomplete>
                  </v-col>
                  <!--<v-col cols="4">
                    <v-autocomplete v-model="item.unidad" :items="catalogues.unidades" label="Unidad" :rules="rules.columna.unidad" :disabled="loading" required></v-autocomplete>
                  </v-col>-->
                </v-row>
              </template>
            </v-form>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small outlined :disabled="forms.new.columnas.length == 1 || loading" color="primary" class="mx-2" @click="removeColumn()">
            Quitar columna
          </v-btn>
          <v-btn small outlined :disabled="forms.new.columnas.length == 10 || loading" color="primary" class="mx-2" @click="addColumn()">
            Agregar columna
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.update" persistent max-width="600px">
      <v-card>
        <v-toolbar dense dark color="secondary" elevation="2">
          <v-toolbar-title>Actualizar plantilla</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn text small :loading="loading" @click="update()">
            Actualizar
            <v-icon right>mdi-check</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="formUpdate" lazy-validation>
              <v-row>
                <v-col cols="12">
                  <v-text-field v-model="forms.update.nombre" label="Nombre" :rules="rules.nombre" :disabled="loading" required></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete v-model="forms.update.fuente_emision" :items="catalogues.fuentes_emisiones" item-text="nombre" item-value="idfuente_emision" label="Fuente de emisión" :rules="rules.fuente_emision" :disabled="loading" required></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="forms.update.operacion" label="Operación" :rules="rules.operacion" :disabled="loading" required></v-text-field>
                </v-col>
              </v-row>
              <template v-if="forms.update.columnas.length">
                <v-divider class="ma-5"></v-divider>
                <v-row justify="center">
                  <span class="font-weight-bold">Columnas</span>
                </v-row>
              </template>
              <template v-for="(item, idx) in forms.update.columnas">
                <v-row :key="idx">
                  <v-col cols="4">
                    <v-text-field v-model="item.id" label="Id" :rules="rules.columna.id" :disabled="loading" required></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field v-model="item.nombre" label="Nombre" :rules="rules.columna.nombre" :disabled="loading" required></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-autocomplete v-model="item.tipo" :items="catalogues.tipos" label="Tipo" :rules="rules.columna.tipo" :disabled="loading" required></v-autocomplete>
                  </v-col>
                  <!--<v-col cols="4">
                    <v-autocomplete v-model="item.unidad" :items="catalogues.unidades" label="Unidad" :rules="rules.columna.unidad" :disabled="loading" required></v-autocomplete>
                  </v-col>-->
                </v-row>
              </template>
            </v-form>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small outlined :disabled="forms.update.columnas.length == 1 || loading" color="primary" class="mx-2" @click="removeColumn()">
            Quitar columna
          </v-btn>
          <v-btn small outlined :disabled="forms.update.columnas.length == 10 || loading" color="primary" class="mx-2" @click="addColumn()">
            Agregar columna
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.import" persistent max-width="600px">
      <v-card>
        <v-toolbar dense dark color="secondary" elevation="2">
          <v-toolbar-title>Importar archivo</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn text small :loading="loading" @click="accept()">
            Actualizar
            <v-icon right>mdi-check</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="formImport" lazy-validation>
              <v-row>
                <v-col cols="12">
                  <v-file-input v-model="forms.import.id" chips show-size label="Seleccionar archivo..." :accept="catalogues.mime_types" :disabled="loading"></v-file-input>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.remove" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Aviso</v-card-title>
        <v-card-text>¿Realmente desea eliminar la plantilla?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" color="blue-grey" @click="cancel()">
            Cancelar
          </v-btn>
          <v-btn text small :loading="loading" color="error" @click="remove()">
            Eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="success" color="success" :timeout="2500" bottom right>
      {{ msgSuccess }}
    </v-snackbar>
    <v-snackbar v-model="error" color="error" :timeout="2500" bottom right>
      {{ msgError }}
    </v-snackbar>
  </v-container>
</template>

<script>
import services from '@/utils/services'
import downloadjs from 'downloadjs'

export default {
  name: 'Template',
  data: () => ({
    loading: false,
    success: false,
    msgSuccess: '',
    error: false,
    msgError: '',
    search: '',
    headers: [
      { text: 'Nombre', align: 'left', sortable: false, value: 'nombre' },
      { text: 'Fuente de emisión', align: 'left', sortable: false, value: 'fuente_emision.nombre' },
      { text: 'Columnas', align: 'left', sortable: false, value: 'columnas.length' },
      { text: 'Acciones', align: 'left', sortable: false, value: 'actions' }
    ],
    items: [],
    selected: [],
    deleted: null,
    catalogues: {
      fuentes_emisiones: [],
      tipos: [
        'texto',
        'entero',
        'decimal',
        'catalogo'
      ],
      unidades: [
        'L',
        'm3',
        'kg',
        'tons'
      ],
      mime_types: [
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ]
    },
    forms: {
      new: {
        nombre: '',
        fuente_emision: '',
        operacion: '',
        columnas: [{
          id: '',
          nombre: '',
          tipo: '',
          // unidad: ''
        }]
      },
      update: {
        idplantilla: '',
        nombre: '',
        fuente_emision: '',
        operacion: '',
        columnas: []
      },
      import: {
        id: null,
        plantilla: ''
      }
    },
    rules: {
      nombre: [
        v => !!v || 'El campo es requerido',
        v => (v && v.length <= 100) || 'El campo excede la longitud máxima'
      ],
      fuente_emision: [
        v => !!v || 'El campo es requerido'
      ],
      operacion: [
        v => !!v || 'El campo es requerido',
        v => (v && v.length <= 500) || 'El campo excede la longitud máxima'
      ],
      columnas: [
        v => !!v || 'El campo es requerido',
        v => (v && v.length <= 10) || 'El campo excede la longitud máxima'
      ],
      columna: {
        id: [
          v => !!v || 'El campo es requerido',
          v => (v && v.length <= 50) || 'El campo excede la longitud máxima',
          v => /^[a-zA-ZñÑ0-9\_\-]{1,50}$/.test(v) || 'El campo es inválido'
        ],
        nombre: [
          v => !!v || 'El campo es requerido',
          v => (v && v.length <= 100) || 'El campo excede la longitud máxima'
        ],
        tipo: [
          v => !!v || 'El campo es requerido'
        ],
        unidad: []
      }
    },
    dialogs: {
      new: false,
      update: false,
      import: false,
      remove: false
    }
  }),
  mounted() {
    this.refresh()
  },
  methods: {
    async refresh() {
      this.loading = true
      const source = await this.axios.get(services.routes.emissionSource)
      if (source.data.data) {
        this.catalogues.fuentes_emisiones = source.data.data
      }
      this.axios.get(services.routes.template)
        .then(response => {
          this.items = response.data.data
        })
        .catch(error => {})
        .finally(() => {
          this.loading = false
        })
    },
    cancel() {
      if (this.dialogs.new) {
        this.$refs.formNew.reset()
        for (let key in this.forms.new) {
          if (key !== 'columnas') {
            this.forms.new[key] = ''
          }
          else {
            this.forms.new[key] = [{
              id: '',
              nombre: '',
              tipo: '',
              // unidad: ''
            }]
          }
        }
        this.dialogs.new = false
      }
      else if (this.dialogs.update) {
        this.$refs.formUpdate.reset()
        for (let key in this.forms.update) {
          if (key !== 'columnas') {
            this.forms.update[key] = ''
          }
          else {
            this.forms.update[key] = []
          }
        }
        this.dialogs.update = false
      }
      else if (this.dialogs.remove) {
        this.dialogs.remove = false
      }
      else if (this.dialogs.import) {
        this.forms.import = {
          id: null,
          plantilla: ''
        }
        this.dialogs.import = false
      }
    },
    save() {
      if (this.$refs.formNew.validate()) {
        this.loading = true
        let data = JSON.parse(JSON.stringify(this.forms.new))
        this.axios.post(services.routes.template, { data })
          .then(response => {
            this.cancel()
            this.refresh()
            this.success = true
            this.msgSuccess = 'Plantilla creada exitosamente'
          })
          .catch(error => {
            this.error = true
            this.msgError = error.response.data.message || 'Servicio no disponible'
          })
          .finally(() => {
            this.loading = false
          })
      }
      else {
        this.error = true
        this.msgError = 'La información es incorrecta'
      }
    },
    view(data) {
      this.forms.update = {
        idplantilla: data.idplantilla,
        nombre: data.nombre,
        fuente_emision: (data.fuente_emision) ? data.fuente_emision.idfuente_emision : '',
        operacion: data.operacion,
        columnas: JSON.parse(JSON.stringify(data.columnas))
      }
      this.dialogs.update = true
    },
    update() {
      if (this.$refs.formUpdate.validate()) {
        this.loading = true
        let data = JSON.parse(JSON.stringify(this.forms.update))
        this.axios.put(services.routes.template, { data })
          .then(response => {
            this.cancel()
            this.refresh()
            this.success = true
            this.msgSuccess = 'Plantilla actualizada exitosamente'
          })
          .catch(error => {
            this.error = true
            this.msgError = error.response.data.message || 'Servicio no disponible'
          })
          .finally(() => {
            this.loading = false
          })
      }
      else {
        this.error = true
        this.msgError = 'La información es incorrecta'
      }
    },
    remove(id) {
      if (!id) {
        this.loading = true
        let id = this.deleted
        this.axios.delete(services.routes.template, { data: { data: { idplantilla: this.deleted } } })
          .then(response => {
            this.cancel()
            this.refresh()
            this.success = true
            this.msgSuccess = 'Plantilla eliminada exitosamente'
          })
          .catch(error => {
            this.error = true
            this.msgError = error.response.data.message || 'Servicio no disponible'
          })
          .finally(() => {
            this.loading = false
          })
      }
      else {
        this.deleted = id
        this.dialogs.remove = true
      }
    },
    removeColumn() {
      if (this.dialogs.new) {
        this.forms.new.columnas.pop()
      }
      else if (this.dialogs.update) {
        this.forms.update.columnas.pop()
      }
    },
    addColumn() {
      if (this.dialogs.new) {
        this.forms.new.columnas.push({
          id: '',
          nombre: '',
          tipo: ''
        })
      }
      else if (this.dialogs.update) {
        this.forms.update.columnas.push({
          id: '',
          nombre: '',
          tipo: ''
        })
      }
    },
    downloadFile(id) {
      this.loading = true
      this.axios.get(services.routes.template + '/file?id=' + id)
        .then(response => {
          let data = response.data.data
          downloadjs(data.archivo, data.nombre, data.archivo.split(';')[0].split(':')[1])
        })
        .catch(error => {
          this.error = true
          this.msgError = error.response.data.message || 'Servicio no disponible'
        })
        .finally(() => {
          this.loading = false
        })
    },
    accept() {
      if (this.forms.import.id) {
        let fr = new FileReader();
        let file = this.forms.import.id
        let size = this.forms.import.id.size
        if (size <= 5000000) {
          this.loading = true
          setTimeout(() => {
            fr.onload = () => {
              let data = {
                idplantilla: this.forms.import.plantilla,
                archivo: fr.result
              }
              this.axios.put(services.routes.template + '/file', { data })
                .then(response => {
                  this.cancel()
                  this.refresh()
                  this.success = true
                  this.msgSuccess = 'Archivo de plantilla actualizado exitosamente'
                })
                .catch(error => {
                  this.error = true
                  this.msgError = error.response.data.message || 'Servicio no disponible'
                })
                .finally(() => {
                  this.loading = false
                })
            }
            fr.onerror = () => {
              this.loading = false
              this.forms.import.id = null
              this.error = true
              this.msgError = 'El archivo no fue leído correctamente'
            }
            fr.readAsDataURL(file);
          }, 500);
        }
        else {
          this.error = true
          this.msgError = 'El archivo ha excedido el límite permitido (5 MB)'
        }
      }
      else {
        this.error = true
        this.msgError = 'Seleccionar archivo'
      }
    },
  }
}
</script>

<style scoped>
</style>
